<template>
	<div ref="d3Div" :id="'heatmap' + id + dir"></div>
</template>

<script>
import * as d3 from 'd3'; // is * necessary, probably no

export default {
	name: 'heatmap',
	props: ['data', 'id', 'dir'],
	data() {
		return {
			svg: null,
			div: null,
			blocks: null,
			height: 200,
			width: 200,
			xgridSize: 10,
			margin: {
				top: 10,
				right: 40,
				bottom: 10,
				left: 20,
			},
			colors: [
				'#ffffd9',
				'#edf8b1',
				'#c7e9b4',
				'#7fcdbb',
				'#41b6c4',
				'#1d91c0',
				'#225ea8',
				'#253494',
				'#081d58',
				'#dd2222',
				'#dd0000',
			],
		};
	},
	watch: {
		data: {
			handler: function (newData, oldData) {
				this.update();
			},
		},
	},
	mounted() {
		var h = 200;

		this.width = this.$refs.d3Div.parentElement.clientWidth - this.margin.left - this.margin.right;
		this.height = h;

		this.xgridSize = Math.floor(this.width / 58) - 1;
		this.ygridSize = Math.floor(h / 24);

		this.svg = d3
			.select('#heatmap' + this.id + this.dir)
			.append('svg')
			.attr('width', this.width + this.margin.left + this.margin.right)
			.attr('height', this.height + this.margin.top + this.margin.bottom)
			.append('g')
			.attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');
		
		if (this.data) { // if data isn't ready, update will pick it up
			this.blocks = this.svg.selectAll('rect').data(this.data);
		}

		this.div = d3
			.select('body')
			.append('div') // declare the tooltip div
			.attr('class', 'tooltip') // apply the 'tooltip' class
			.style('opacity', 0);

		window.addEventListener('resize', this.resize);
		this.update();
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.resize);
	},
	methods: {
		update() {
			if (!this.data) {
				return;
			}

			this.blocks = this.svg.selectAll('rect').data(this.data);
			this.blocks.exit().remove();

			var vm = this;

			var block = this.blocks
				.enter()
				.append('rect')
				.attr('x', function (d) {
					return d[1] * vm.xgridSize;
				})
				.attr('y', function (d) {
					return d[2] * vm.ygridSize;
				})
				.attr('rx', 4)
				.attr('ry', 4)
				.attr('class', 'hour bordered')
				.attr('width', this.xgridSize)
				.attr('height', this.ygridSize)
				.style('fill', function (d) {
					if (vm.dir == 'rx') return vm.colors[d[3]];
					else return vm.colors[d[4]];
				})
				.on('mouseover', function (mouseEvent, d) {
					vm.div.transition().duration(300).style('opacity', 0);
					vm.div.transition().duration(200).style('opacity', 0.9);
					vm.div
						.html(function () {
							if (vm.dir == 'rx') {
								return (
									'<strong>' + d[0] + "</strong> <span style='color:yellow'>" + d[5] + 'B</span> '
								);
							} else return '<strong>' + d[0] + "</strong> <span style='color:yellow'>" + d[6] + 'B</span>';
						})
						.style('left', mouseEvent.pageX + 'px')
						.style('top', mouseEvent.pageY - 48 + 'px')
						.style('padding', '10px')
						.style('border-radius', '4px')
						.style('color', '#ffffff')
						.style('background', 'rgba(0, 0, 0, 0.8)');
				})
				.on('mouseout', function () {
					vm.div.transition().duration(100).style('opacity', 0);
				});
		},
		resize() {
			if (this.svg) {
				if (
					this.width !=
					this.$refs.d3Div.parentElement.clientWidth - this.margin.left - this.margin.right
				) {
					this.width =
						this.$refs.d3Div.parentElement.clientWidth - this.margin.left - this.margin.right;
					this.xgridSize = Math.floor(this.width / 58) - 1;

					this.svg
						.attr('width', this.width)
						.attr('height', this.height + this.margin.top + this.margin.bottom);

					this.xgridSize =
						Math.floor(
							(this.$refs.d3Div.parentElement.clientWidth - this.margin.left - this.margin.right) /
								58
						) - 1;

					this.update();
				}
			}
		},
	},
};
</script>

<style>
</style>